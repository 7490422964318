<template>
  <div>
    <van-row style="font-size:14px">
      <van-col span="8" @click="toTourInfo(tour.id)">
        <van-row style="font-size: 18px; font-weight: 550"> {{tour.start_date}} </van-row>
        <van-row style="font-weight: bold; margin-top: 10px;font-size">
          {{ tour.start_addr }}
        </van-row>
      </van-col>
      <van-col span="8" style="text-align: center">
        <van-row style="color: grey; height: 10px"> {{tour.days}}天 </van-row>
        <van-row style="height: 10px">
          <van-divider />
        </van-row>
        <van-row style="height: 20px" v-if="showNodesButton">
          <van-button
            type="default"
            round
            size="small"
            style="height: 20px"
            @click="tripNodesClick()"
            >行程景点</van-button
          >
        </van-row>
        <van-row style="height: 20px" v-if="!showNodesButton && tour.status === 3">
          <van-button
            type="default"
            round
            size="small"
            style="height: 20px"
            @click="showCancelComment"
            >取消原因</van-button
          >
        </van-row>
      </van-col>
      <van-col span="8" style="text-align: right" @click="toTourInfo(tour.id)">
        <van-row style="font-size: 18px; font-weight: 550"> {{tour.end_date}} </van-row>
        <van-row style="font-weight: bold; margin-top: 10px">
          {{ tour.end_addr }}
        </van-row>
      </van-col>
    </van-row>
    <van-overlay :show="showTripNodesDialog" @click="showTripNodesDialog = false">
      <card class="wrapper">
        <template #content>
          <van-row class="align-center">
            <h6 class="row-title" style="font-size:.3rem">行程景点</h6>
          </van-row>
          <van-row style="padding-bottom:5px;margin-top:.3rem">
            <van-col span="4" class="row-title">景点</van-col>
            <van-col span="8" class="row-title" style="text-align:center">到达</van-col>
            <van-col span="8" class="row-title" style="text-align:center">出发</van-col>
            <van-col span="4" class="row-title" style="text-align:right">逗留</van-col>
          </van-row>
          <van-row v-for="(node, index) in nodes" :key="index" style="margin-top:.1rem">
            <van-col span="4" class="row-item van-ellipsis" style="padding-right:.3rem">{{node.node_name}} &nbsp;</van-col>
            <van-col span="8" class="row-item van-ellipsis align-center">{{node.start_time}}</van-col>
            <van-col span="8" class="row-item van-ellipsis align-center">{{node.end_time}}</van-col>
            <van-col span="4" class="row-item van-ellipsis " style="text-align:right">{{node.stay_time}}</van-col>
          </van-row>
          <van-row v-if="nodes === 0" style="padding:10px;text-align:center;background:#f6f6f6">
            该行程暂无景点
          </van-row>
        </template>
      </card>
    </van-overlay>
  </div>
</template>

<script>
import {Dialog} from 'vant'
import {mapGetters} from 'vuex'
import card from '@/views/components/Card'
export default {
  components: {
    card
  },
  props: {
    tour: {
      type: Object,
      default: () => {},
    },
    titleClickable: {
      type: Boolean,
      default: true
    },
    showNodesButton: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    ...mapGetters('travel_list', {
      nodes: "currentTravelListNodes"
    })
  },
  data: () => ({
    showTripNodesDialog: false,
  }),
  methods: {
    tripNodesClick() {
      this.$store.dispatch('travel_list/getTravelListNodes', 
        this.tour.id)
      this.showTripNodesDialog = true;
    },
    toTourInfo(id) {
      if(this.titleClickable) {
        this.$router.push(`/tour/info/${id}`)
      }
    },
    showCancelComment() {
      const _self = this
      Dialog.alert({
        title: '取消原因',
        message: _self.tour.comment == null || _self.tour.comment == ''? '无': _self.tour.comment 
      })
    }
  },
};
</script>

<style scoped>
.row-item {
  font-size: .25rem;
  color: #6f6f6f;

}
.row-title {
  font-weight: bold;
  font-size: .25rem;
}
.wrapper {
  align-items: center;
  justify-content: center;
  min-height: 50vh;
  width: 92%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 3rem;
}
.align-center {
  text-align:center;
}
</style>