<template>
  <div>
    <card style="margin-top: 10px; font-size: 14px">
      <template #content>
        <van-row style="color:#808080">
          <span>{{tour.list_name}}</span>
        </van-row>
        <tourInfoTop :tour="tour" :titleClickable="clickable"></tourInfoTop>
        <van-row style="height: 20px; color: grey; margin-top: 10px" @click="toTourInfo(tour.id)">
          出发日期：{{ tour.start_date }}
        </van-row>
        <van-row style="color: grey" >
          <van-col span="9" @click="toTourInfo(tour.id)">
            <div style="margin-top:.1rem">
              团队人数：{{ tour.tourists_count }}
            </div>
          </van-col>
          <van-col span="5" style="text-align:right">
            <van-button type="default" round size="small" style="height:20px" @click="showQrCode=true">二维码</van-button>
          </van-col>
          <van-col span="5" style="text-align:right">
            <van-button round size="small" type="primary" color="#6F6F6F23"
              style="height:20px;margin-top:-15px;"
              v-if="(tour.status === 1 || tour.status === 0) && clickable"
              @click="showCancelDialog=true">
              <span style="color:#606060">
                取消
              </span>
            </van-button>
          </van-col>
          <van-col span="5" style="text-align:right;text-size:10px;" @click="toTourInfo(tour.id)">
            <van-button round :color="tourStateStyle.color"
              style="height:20px;margin-left:5px;width:60px;"
              size="small" >
              <span :style="{color: tourStateStyle.text_color}">{{tour.status_text}}</span>
            </van-button>
          </van-col>
        </van-row>
      </template>
    </card>
    <van-dialog v-model="showCancelDialog" title="取消行程" show-cancel-button 
      @confirm="cancelTour" :before-close="(action, done) => beforeConfirmCancel(action, done)">
      <van-field type="textarea" rows="2" label="取消原因" maxlength="100" show-word-limit
        placeholder="请输入内容" v-model="cancelComment"/>
    </van-dialog>
    <van-overlay  z-index="100" :show="showQrCode" @click="showQrCode = false">
      <div class="qrimg-wrapper" >
        <qrCode
        :text="`${baseVistorFrontEndUrl}tour/item/${tour.id}`"
        :logoSrc="tour.image_url == ''? '' :`${tour.image_url}?cache`"
        :margin="0"
        colorDark="#333"
        colorLight="#fff"
        :logoScale="0.2"
        :size="200"
      ></qrCode>
    
      </div>
    </van-overlay>
  </div>
</template>

<script>
import card from "./Card.vue";
import tourInfoTop from './TourInfoTop.vue'
import qrCode from 'vue-qr'
import api from '@/services/apis.js'
import {Toast} from 'vant'
import {baseVistorFrontEndUrl} from "@/utils/constants"
export default {
  components: {
    card,
    tourInfoTop,
    qrCode
  },
  props: {
    tour: {
      type: Object,
      default: () => {},
    },
    clickable: {
      type: Boolean,
      default: true
    }
  },
  data: () => ({
    showQrCode: false,
    showCancelDialog: false,
    cancelComment: "",
    baseVistorFrontEndUrl: baseVistorFrontEndUrl
  }),
  computed: {
    tourStateStyle() {
      switch (this.tour.status) {
        case 0: return {color: "#4168EE12", text_color: "#4168EE"};
        case 1: return {color: "#4168EE", text_color: "#fff"};
        case 2: return {color: "#13C2C212", text_color: "#13C2C2"};
        case 3: return {color: "#6f6f6f23", text_color: "#606060"};
      }
      return ''
    }
  },
  methods: {
    toTourInfo(id) {
      if(this.clickable) {
        this.$router.push(`/tour/info/${id}`)
      }
    },
    cancelTour() {
      let _self = this
      if(this.cancelComment != '' && this.cancelComment != null) {
        api.travel_lists_change_status({
            travel_list_id: _self.tour.id,
            status: 3,
            comment: _self.cancelComment
        }).then((res) => {
          if(res.data.status === 200){
            this.$store.dispatch("travel_list/getAllTravelLists", 0)
            this.$store.dispatch("travel_list/getAllTravelLists", 1)
            this.$store.dispatch("travel_list/getAllTravelLists", 3)
          }
        })
      }
    },
    beforeConfirmCancel(action, done) {
      if(action === 'confirm') {
        if(this.cancelComment == '' || this.cancelComment == null) {
          done(false)
          Toast('请输入取消原因')
        } else {
          done()
        }
      } else {
        done()
      }
    }
  },
};
</script>

<style>
.qrimg-wrapper{
   /* width: 90vw; */
  margin: 0 auto;
  background: #fff;
  border-radius: 20px;
  /* border-radius: 20px; */
  /* height: 40vh; */
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: .3rem;
  overflow-y: scroll;
  text-align: center;
}
</style>